import request from '@/utils/request'

/** *
 * 获取指定设备的预报警列表信息
 */
export function getDeviceAlarmLogList(deviceType) {
  return request({
    url: '/Device/GetDeviceAlarmLogList',
    method: 'GET',
    params: {
      deviceType: deviceType
    }
  })
}

/** *
 * 获取指定设备指定时间阶段的预报警折线图
 */
export function getDeviceAlarmLineStat(deviceType, top) {
  return request({
    url: '/Device/GetDeviceAlarmLineStat',
    method: 'GET',
    params: {
      deviceType: deviceType,
      topDay: top
    }
  })
}

/** *
 * 获取项目关联的所有设备信息
 */
export function getPrjDevices() {
  return request({
    url: '/Device/GetPrjDevices',
    method: 'GET'
  })
}

/**
 * 获取指定人货梯设备详情
 */
export function getLifterDeviceInfo(params) {
  return request({
    url: '/Device/GetLifterDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取指定塔吊设备详情
 */
export function getToweDeviceInfo(params) {
  return request({
    url: '/Device/GetToweDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取指定电箱设备详情
 */
export function getPowerDeviceInfo(params) {
  return request({
    url: '/Device/GetPowerDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取指定环境监测设备详情
 */
export function getEnvensorDeviceInfo(params) {
  return request({
    url: '/Device/GetEnvensorDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取指定监控视频设备详情
 */
export function getVideoDeviceInfo(params) {
  return request({
    url: '/Device/GetVideoDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取指定人行闸设备详情
 */
export function getManLockDeviceInfo(params) {
  return request({
    url: '/Device/GetManLockDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取指定车道闸设备详情
 */
export function getDrivewayGatDeviceInfo(params) {
  return request({
    url: '/Device/GetDrivewayGatDeviceInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取智能AI设备详情
 */
export function getAIInfo(params) {
  return request({
    url: '/Device/GetAIInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取高支模设备详情
 */
export function getHighFormworkInfo(params) {
  return request({
    url: '/Device/GetHighFormworkInfo?deviceId=' + params,
    method: 'POST'
  })
}

/**
 * 获取设备报警记录
 */
export function getDeviceAlarmLogPage(data) {
    return request({
        url: '/Device/GetDeviceAlarmLogPage',
        method: 'POST',
        data
    })
}

/**
 * 获取人货梯数据
 */

export function getLiferData(data) {
  return request({
    url: '/Device/GetAllLifterDeviceInfo',
    method: 'post',
    data
  })
}

/**
 * 获取塔吊数据
 */

export function getAllTower(data) {
  return request({
    url: '/Device/GetAllToweDeviceInfo',
    method: 'post',
    data
  })
}

/**
 * 获取塔吊载重和高度折线图数据
 */
export function getGraphData({ deviceType, channel, indication, beginTime, endTime }) {
  return request({
    url: '/Graph/GetDataOfIndication',
    method: 'get',
    params: {
      deviceType: deviceType,
      channel: channel,
      indication: indication,
      beginTime: beginTime,
      endTime: endTime
    }
  })
}

/** *
 * 获取塔吊载重记录和高度记录数据
 */
export function getWeightAndHeightStat(data) {
    return request({
        hostType: 'BILOG',
        url: '/Tower/GetWeightAndHeightStat',
        method: 'post',
        data
    })
}

/**
 * 获取塔吊防碰撞数据
 */
export function getAntiCollisionsData(sn) {
    return request({
        url: '/Device/GetAntiCollisions',
        method: 'get',
        params: {
            sn: sn
        }
    })
}
