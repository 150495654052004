<template>
  <div class="machine-view">
    <div class="machine-view-main">
      <div class="ztb-map-wrap">
        <img class="ztb-map" :src="planarGraphImg" @load="onMapLoad()">
        <div v-for="(item, index) in datas" :key="index">
          <div
            class="machine-icon"
            :class="machineIconClass[item.status]"
            :style="getMachineCoordinate(item, 15, 5)"
          />
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.name"
            placement="top"
          >
            <img
              :src="typeImgMap[item.type]"
              class="item-icon"
              :style="getMachineCoordinate(item, 11, -1)"
              @click="imgClick(item)"
            >
          </el-tooltip>
        </div>
      </div>
      <div class="machine-view-menu">
        <span
          class="all"
          :class="menuId === 7 ? 'checked' : ''"
          @click="menuChange(7)"
        >
          <img :src="allImg">
          全部
        </span>
        <div
          v-if="checkMenu('智能 AI')"
          :class="menuId === 9 ? 'checked' : ''"
          @click="menuChange(9)"
        >
          <img :src="menuId !== 9 ? znaiImg : znai_cImg"> 智能 AI
        </div>
        <div
          v-if="checkMenu('视频监控')"
          :class="menuId === 4 ? 'checked' : ''"
          @click="menuChange(4)"
        >
          <img :src="menuId !== 4 ? jkImg : jk_cImg"> 视频监控
        </div>
        <div
          v-if="checkMenu('用电监测')"
          :class="menuId === 5 ? 'checked' : ''"
          @click="menuChange(5)"
        >
          <img :src="menuId !== 5 ? dxImg : dx_cImg"> 用电监测
        </div>
        <div
          v-if="checkMenu('环境监测')"
          :class="menuId === 6 ? 'checked' : ''"
          @click="menuChange(6)"
        >
          <img :src="menuId !== 6 ? hjImg : hj_cImg"> 环境监测
        </div>
        <div
          v-if="checkMenu('塔吊监测')"
          :class="menuId === 0 ? 'checked' : ''"
          @click="menuChange(0)"
        >
          <img :src="menuId !== 0 ? tdImg : td_cImg"> 塔吊监测
        </div>
        <div
          v-if="checkMenu('人货梯')"
          :class="menuId === 1 ? 'checked' : ''"
          @click="menuChange(1)"
        >
          <img :src="menuId !== 1 ? rhtImg : rht_cImg"> 人货梯
        </div>
        <div
          v-if="checkMenu('车道闸')"
          :class="menuId === 2 ? 'checked' : ''"
          @click="menuChange(2)"
        >
          <img :src="menuId !== 2 ? cdzImg : cdz_cImg"> 车道闸
        </div>
        <div
          v-if="checkMenu('人行闸')"
          :class="menuId === 3 ? 'checked' : ''"
          @click="menuChange(3)"
        >
          <img :src="menuId !== 3 ? rxzImg : rxz_cImg"> 人行闸
        </div>
        <div
          v-if="checkMenu('高支模')"
          :class="menuId === 8 ? 'checked' : ''"
          @click="menuChange(8)"
        >
          <img :src="menuId !== 8 ? gzmImg : gzm_cImg"> 高支模
        </div>
      </div>
    </div>
    <info-moal :visible.sync="dialogInfoMoalVisible" :record="record" />
    <loading v-if="loading" />
  </div>
</template>
<script>
import Loading from '@/components/loading'
import { getPrjDevices } from '@/api/device'
import { hasVal } from '@/utils/index'
import infoMoal from './components/infoMoal' // waves directive
export default {
  name: 'MachineHome',
  components: { infoMoal, Loading },
  data() {
    return {
      loading: false,
      allImg: require('../../assets/image/machine/tbjx001.png'),
      znaiImg: require('../../assets/image/machine/tbjx010-1.png'),
      znai_cImg: require('../../assets/image/machine/tbjx010-2.png'),
      znai_cImg3: require('../../assets/image/machine/tbjx010-3.png'),
      jkImg: require('../../assets/image/machine/tbjx0006-1.png'),
      jk_cImg: require('../../assets/image/machine/tbjx0006-2.png'),
      jk_cImg3: require('../../assets/image/machine/tbjx0006-3.png'),
      dxImg: require('../../assets/image/machine/tbjx007-1.png'),
      dx_cImg: require('../../assets/image/machine/tbjx007-2.png'),
      dx_cImg3: require('../../assets/image/machine/tbjx007-3.png'),
      hjImg: require('../../assets/image/machine/tbjx008-1.png'),
      hj_cImg: require('../../assets/image/machine/tbjx008-2.png'),
      hj_cImg3: require('../../assets/image/machine/tbjx008-3.png'),
      tdImg: require('../../assets/image/machine/tbjx002-1.png'),
      td_cImg: require('../../assets/image/machine/tbjx002-2.png'),
      td_cImg3: require('../../assets/image/machine/tbjx002-3.png'),
      rhtImg: require('../../assets/image/machine/tbjx003-1.png'),
      rht_cImg: require('../../assets/image/machine/tbjx003-2.png'),
      rht_cImg3: require('../../assets/image/machine/tbjx003-3.png'),
      cdzImg: require('../../assets/image/machine/tbjx004-1.png'),
      cdz_cImg: require('../../assets/image/machine/tbjx004-2.png'),
      cdz_cImg3: require('../../assets/image/machine/tbjx004-3.png'),
      rxzImg: require('../../assets/image/machine/tbjx005-1.png'),
      rxz_cImg: require('../../assets/image/machine/tbjx005-2.png'),
      rxz_cImg3: require('../../assets/image/machine/tbjx005-3.png'),
      gzmImg: require('../../assets/image/machine/tbjx009-1.png'),
      gzm_cImg: require('../../assets/image/machine/tbjx009-2.png'),
      gzm_cImg3: require('../../assets/image/machine/tbjx009-3.png'),
      menuId: 7,
      planarGraphImg: '',
      posionData: [],
      datas: [],
      deviceTypeOptions: [],
      menuIdMap: [
        '塔吊监测',
        '人货梯',
        '车道闸',
        '人行闸',
        '视频监控',
        '用电监测',
        '环境监测',
        'all',
        '高支模',
        '智能 AI',
        '人员定位',
        '基坑监测',
        '钢支撑'
      ],
      typeImgMap: {
        塔吊监测: require('../../assets/image/machine/tbjx002-3.png'),
        人货梯: require('../../assets/image/machine/tbjx003-3.png'),
        车道闸: require('../../assets/image/machine/tbjx004-3.png'),
        人行闸: require('../../assets/image/machine/tbjx005-3.png'),
        视频监控: require('../../assets/image/machine/tbjx0006-3.png'),
        用电监测: require('../../assets/image/machine/tbjx007-3.png'),
        环境监测: require('../../assets/image/machine/tbjx008-3.png'),
        高支模: require('../../assets/image/machine/tbjx009-3.png'),
        '智能 AI': require('../../assets/image/machine/tbjx010-3.png')
      },
      machineIconClass: ['offline-bg', '', 'warinng-bg'],
      containerHeight: 200,
      imgOriginHeight: 200,
      scale: 0,
      dialogInfoMoalVisible: false,
      record: null
    }
  },
  mounted() {
    this.removeMachine()
    window.onresize = this.resize()
  },
  methods: {
    resize() {
      this.containerHeight = `${document.documentElement.clientHeight}`
      this.imgOriginHeight = document.querySelector(`.ztb-map`)
        ? document.querySelector(`.ztb-map`).naturalHeight
        : 200
      this.scale = this.containerHeight / this.imgOriginHeight
    },
    removeMachine() {
      this.loading = true
      this.posionData = []
      this.deviceTypeOptions = []
      getPrjDevices().then((res) => {
        const { data } = res
        this.planarGraphImg = data.planarGraph
        if (hasVal(data.devices)) {
          data.devices.forEach((types) => {
            if (hasVal(types.text) && types.text.length > 0) {
              this.deviceTypeOptions.push(types.value)
              types.text.forEach((item) => {
                this.posionData.push({ ...item, type: types.value })
              })
            }
          })
          this.resize()
          this.menuChange(7)
          this.loading = false
        }
      })
    },
    menuChange(menuId) {
      this.menuId = menuId
      this.datas = this.posionData.filter(
        (item) => item.type === this.menuIdMap[this.menuId] || this.menuId === 7
      )
    },
    onMapLoad() {
      if (document.querySelector(`.ztb-map`)) {
        var img = new Image()
        img.src = document.querySelector(`.ztb-map`).src
        this.imgOriginHeight = img.height
      } else {
        this.imgOriginHeight = 200
      }
      this.scale =
        this.imgOriginHeight > 0
          ? this.containerHeight / this.imgOriginHeight
          : 0
    },
    getMachineCoordinate(item, top, left) {
      top = item.lat * this.scale - top
      left = item.lng * this.scale - left
      return 'top:' + top + 'px;left:' + left + 'px;'
    },
    imgClick(item) {
      this.record = item
      this.dialogInfoMoalVisible = true
    },
    checkMenu(val) {
      var _item = hasVal(this.deviceTypeOptions)
        ? this.deviceTypeOptions.filter((item) => item === val)
        : null
      return hasVal(_item)
    }
  }
}
</script>
<style lang="scss" scoped>
.machine-view {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  .main-list-pitCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 8px;
    > div {
      width: 24.3%;
      margin: 4px;
    }
  }
  &-main {
    margin-top: 0.1px;
    height: calc(100vh - 0.1px);
    min-height: 700px;
    max-width: 100%;
    text-align: center;
    cursor: pointer;
    .ztb-map-wrap {
      height: 100%;
      width: 100%;
      position: relative;
      display: inline-block;
      .ztb-map {
        height: 100%;
        width: 100%;
      }
      .machine-icon {
        height: 32px;
        width: 32px;
        background-image: url(../../assets/image/machine/machine-normal.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        cursor: pointer;
      }
      .warinng-bg {
        background-image: url(../../assets/image/machine/machine-warning.png);
      }
      .offline-bg {
        background-image: url(../../assets/image/machine/machine-offline.png);
        opacity: 0.8;
      }
      .item-icon {
        position: absolute;
        height: 20px;
        width: 20px;
        z-index: 1;
      }
    }
  }
  &-menu {
    width: 119px;
    background: #002644;
    border: 2px solid #10568e;
    opacity: 0.9;
    box-sizing: border-box;
    position: fixed;
    right: 0;
    top: 100px;
    color: #41a5d8;
    font-size: 20px;

    > div {
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 6px 0;

      > img {
        height: 32px;
        width: 35px;
      }
    }
    .checked {
      background-color: #0c0c0c;
      color: #fb8a4c;
    }
    .all {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #10568e;
      > img {
        height: 22px;
      }
    }
  }
  .ant-pagination {
    margin-top: 15px;
    .ant-pagination-total-text {
      color: #ffffff;
    }
    .ant-pagination-jump-next {
      color: #ffffff;
    }
  }
}
</style>
