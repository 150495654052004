<template>
  <el-dialog
    :visible.sync="dialogInfoMoalVisible"
    :modal-append-to-body="false"
    :modal="false"
    widht="900px"
    class="machine-modal"
  >
    <div v-if="hasVal(record)" slot="title" class="machine-modal-title">
      <img :src="typeImgMap[record.type]">
      {{ name }}
    </div>
    <div class="machine-modal-view-main">
      <div class="common-wrap">
        <div v-if="hasVal(rhtHeaderOptions) && rhtHeaderOptions.length > 1">
          <div class="tabs-header">
            <div class="tab-bottom" />
            <div
              v-for="(cell, index) in rhtHeaderOptions"
              :key="index"
              :class="cell.sn === rhtSn ? 'checked' : ''"
              @click="switchRhtSn(cell.sn)"
            >
              {{ cell.name }}
            </div>
          </div>
        </div>
        <div class="commn-info-list">
          <div v-for="(row, index) in baseInfo" :key="index" class="in-key">
            <span>{{ row.key }}：</span>
            <span
              :style="row.value === '在线' ? 'backgroundColor:#1B7125;' : ''"
              :class="row.key === '状态' && row.value ? 'info-status' : ''"
            >
              <el-image
                v-if="row.type === 'image'"
                style="width: 70px; height: 40px"
                :src="row.value"
                :preview-src-list="previewImg(row.value)"
              />
              {{ row.type === "image" ? "" : row.value }}
            </span>
          </div>
        </div>
        <div v-if="existsTableList()" class="basic-table">
          <table-content
            :is-rxz="record.type === '人行闸'"
            :val-options="tableList"
          />
        </div>
        <div v-if="!noVideo || !noWarning" class="machine-tabs">
          <div class="tabs-header">
            <div class="tab-bottom" />
            <div
              v-if="!noVideo"
              :class="tabIndex === 1 ? 'checked' : ''"
              @click="setState(1)"
            >
              监控视频
            </div>
            <div
              v-if="!noWarning"
              :class="tabIndex === 2 ? 'checked' : ''"
              @click="setState(2)"
            >
              报警记录
            </div>
          </div>
          <div v-if="tabIndex === 1" class="video-group">
            <div v-if="!hasVal(videoDevices)" class="empty-video">
              暂无绑定任何监控设备
            </div>
            <div
              v-for="(item, index) in videoDevices"
              v-else
              :key="index"
              class="video-group-item"
              @click="openVideo(item)"
            >
              <div class="fake-video">
                <div class="start-btn">
                  <div />
                </div>
              </div>
              <div :title="item.name" class="video-group-item-name">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div
            v-else-if="tabIndex === 2"
            class="warning-table"
            :style="hasVal(config) ? '' : 'display: flex;'"
          >
            <div v-if="hasVal(config)">
              <dv-scroll-board :config="config" style="height: 200px" />
            </div>
            <div v-else class="empty-video">暂无报警数据</div>
          </div>
        </div>
      </div>
      <el-dialog
        :visible.sync="dialogVideoVisible"
        :modal-append-to-body="false"
        :modal="false"
        widht="900px"
        class="machine-modal"
      >
        <div slot="title" class="machine-modal-title">
          {{ dialogVideoTitle }}
        </div>
        <monitor :box-height="460" :item="monitorItem" />
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVideoVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </el-dialog>
</template>
<script>
import {
  getLifterDeviceInfo,
  getToweDeviceInfo,
  getPowerDeviceInfo,
  getEnvensorDeviceInfo,
  getVideoDeviceInfo,
  getManLockDeviceInfo,
  getDrivewayGatDeviceInfo,
  getAIInfo,
  getHighFormworkInfo,
  getDeviceAlarmLogPage
} from '@/api/device'
import monitor from '@/components/monitor'
import { hasVal, parseTime } from '@/utils/index'
import TableContent from './table-content' // waves directive
export default {
  name: 'MachineInfo',
  components: { TableContent, monitor },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialogInfoMoalVisible: false,
      name: '',
      tabIndex: 1,
      dialogVideoTitle: '',
      dialogVideoVisible: false,
      monitorItem: null,
      typeImgMap: {
        塔吊监测: require('../../../assets/image/machine/tbjx002-2.png'),
        人货梯: require('../../../assets/image/machine/tbjx003-2.png'),
        车道闸: require('../../../assets/image/machine/tbjx004-2.png'),
        人行闸: require('../../../assets/image/machine/tbjx005-2.png'),
        视频监控: require('../../../assets/image/machine/tbjx0006-2.png'),
        用电监测: require('../../../assets/image/machine/tbjx007-2.png'),
        环境监测: require('../../../assets/image/machine/tbjx008-2.png'),
        高支模: require('../../../assets/image/machine/tbjx009-2.png'),
        '智能 AI': require('../../../assets/image/machine/tbjx010-2.png')
      },
      baseInfo: [],
      rhtOptions: [],
      rhtHeaderOptions: [],
      rhtVideoOptions: [],
      rhtSn: '',
      windMap: [
        '东北偏北',
        '东北',
        '东北偏东',
        '正东',
        '东南偏东',
        '东南',
        '东南偏南',
        '正南',
        '西南偏南',
        '西南',
        '西南偏西',
        '正西',
        '西北偏西',
        '西北',
        '西北偏北',
        '正北'
      ],
      videoDevices: [],
      config: [],
      columnWidth: [30, 710, 160],
      aligns: ['right', 'left', 'center'],
      noVideo: true,
      noWarning: true,
      listQuery: {
        sn: '',
        pageIndex: 1,
        pageSize: 50
      },
      tableList: []
    }
  },
  watch: {
    record(val) {
      this.removeInfo()
    },
    dialogInfoMoalVisible(val) {
      this.$emit('update:visible', val)
    },
    visible(val) {
      this.dialogInfoMoalVisible = val
    }
  },
  methods: {
    /**
     * 数据初始化
     */
    removeInfo() {
      const _that = this
      if (hasVal(_that.record.id)) {
        _that.baseInfo = []
        _that.videoDevices = []
        _that.config = null
        _that.noWarning = true
        _that.noVideo = false
        _that.tabIndex = 1
        _that.rhtHeaderOptions = []
        _that.rhtVideoOptions = []
        _that.rhtOptions = []
        switch (_that.record.type) {
          case '人行闸':
            _that.removeManLockDeviceInfo()
            break
          case '塔吊监测':
            _that.removeToweDeviceInfo()
            break
          case '用电监测':
            _that.removePowerDeviceInfo()
            break
          case '环境监测':
            _that.removeEnvensorDeviceInfo()
            break
          case '车道闸':
            _that.removeDrivewayGatDeviceInfo()
            break
          case '视频监控':
            _that.removeVideoDeviceInfo()
            break
          case '人货梯':
            _that.removeLifterDeviceInfo()
            break
          case '高支模':
            _that.removeHighFormworkInfo()
            break
          case '智能 AI':
            _that.noVideo = true
            _that.removeAIInfo()
            break
        }
      }
    },
    /**
     * 加载设备对应的报警信息
     */
    removeDeviceAlarmLog(sn) {
      this.listQuery.sn = sn
      this.noWarning = false
      getDeviceAlarmLogPage(this.listQuery).then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items) && data.items.length > 0) {
          data.items.forEach((item) => {
            _itemData = []
            _itemData.push(
              "<div style='width:12px;height:12px;border-radius:12px;margin-top: 20px;margin-left: 5px;background:" +
                (item.level === 1 ? '#FF0000' : '#fad400') +
                "'></div>"
            )
            _itemData.push(
              "<span style='margin-right:5px;color:#dca23f;'>[" +
                item.name +
                ']</span>' +
                item.content
            )
            _itemData.push(item.time)
            _data.push(_itemData)
          })
          this.config = {
            columnWidth: this.columnWidth,
            align: this.aligns,
            data: _data,
            rowNum: 4,
            evenRowBGC: '',
            oddRowBGC: 'rgba(0,255,255,.2)'
          }
        }
      })
    },
    /**
     * 人货梯信息
     */
    removeLifterDeviceInfo() {
      getLifterDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            data.latestDatas.forEach((latestData) => {
              this.rhtHeaderOptions.push({
                sn: latestData.sn,
                name: latestData.name
              })
              this.rhtVideoOptions.push({
                sn: latestData.sn,
                data: hasVal(latestData.videoDevices)
                  ? latestData.videoDevices
                  : []
              })
              this.rhtOptions.push({
                sn: latestData.sn,
                data: [
                  {
                    key: '状态',
                    value: latestData.statusTxt
                  },
                  {
                    key: '负责人',
                    value: this.getContact(
                      data.superintendentName,
                      data.superintendentPhone
                    )
                  },
                  {
                    key: '采集时间',
                    value: parseTime(
                      latestData.time,
                      '{y}-{m}-{d} {h}:{i}:{s}'
                    )
                  },
                  {
                    key: '报警次数',
                    value: (latestData.alarmCount || 0) + ' 次'
                  },
                  {
                    key: '人数',
                    value: (latestData.peopleCount || 0) + ' 人'
                  },
                  {
                    key: '载重',
                    value: (latestData.weight || 0) + ' 吨'
                  },
                  {
                    key: '速度',
                    value: (latestData.speed || 0) + ' m/s'
                  },
                  {
                    key: '高度',
                    value: (latestData.height || 0) + ' m'
                  },
                  {
                    key: '楼层',
                    value: (latestData.floor || 0) + ' 层'
                  },
                  {
                    key: '倾角X（-9.99~9.99°）',
                    value: (latestData.obliguityX || 0) + ' °'
                  },
                  {
                    key: '倾角Y（-9.99~9.99°）',
                    value: (latestData.obliguityY || 0) + ' °'
                  },
                  {
                    key: '风速',
                    value: (latestData.windSpeed || 0) + ' m/s'
                  },
                  {
                    key: '风级（0~12级）',
                    value: (latestData.windLevel || 0) + ' 级'
                  },
                  {
                    key: '司机姓名',
                    value: latestData.operName
                  },
                  {
                    key: '司机电话号码',
                    value: latestData.operPhone
                  }
                ]
              })
            })
            this.rhtSn = this.rhtOptions[0].sn
            this.baseInfo = this.rhtOptions[0].data
            this.removeDeviceAlarmLog(latestData.sn)
          }
        }
      })
    },
    /**
     * 塔吊
     */
    removeToweDeviceInfo() {
      getToweDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              {
                key: '负责人',
                value: this.getContact(
                  data.superintendentName,
                  data.superintendentPhone
                )
              },
              {
                key: '司机',
                value: this.getContact(
                  latestData.operName,
                  latestData.operPhone
                )
              },
              {
                key: '采集时间',
                value: parseTime(latestData.time, '{y}-{m}-{d} {h}:{i}:{s}')
              },
              {
                key: '报警次数',
                value: (latestData.alarmCount || 0) + ' 次'
              },
              {
                key: '黑匣/传感器名称',
                value: latestData.name
              },
              {
                key: '黑匣/传感器序号',
                value: latestData.sn
              },
              {
                key: '额定载重',
                value: (latestData.ratedWeight || 0) + ' 吨'
              },
              {
                key: '载重',
                value: (latestData.weight || 0) + ' 吨'
              },
              {
                key: '力矩百分比（百分之）',
                value: latestData.moment1 || 0
              },
              {
                key: '倍率',
                value: (latestData.multiple || 0) + ' 倍'
              },
              {
                key: '风级',
                value: (latestData.multiple || 0) + ' 级'
              },
              {
                key: '高度',
                value: (latestData.height || 0) + ' m'
              }
            ]
            this.removeDeviceAlarmLog(latestData.sn)
          }
        }
      })
    },
    /**
     * 电箱
     */
    removePowerDeviceInfo() {
      getPowerDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              {
                key: '负责人',
                value: this.getContact(
                  data.superintendentName,
                  data.superintendentPhone
                )
              },
              {
                key: '采集时间',
                value: parseTime(latestData.time, '{y}-{m}-{d} {h}:{i}:{s}')
              },
              {
                key: '报警次数',
                value: latestData.alarmCount || 0 + ' 次'
              },
              {
                key: '黑匣/传感器名称',
                value: latestData.name
              },
              {
                key: '黑匣/传感器序号',
                value: latestData.sn
              },
              {
                key: '电箱温度',
                value: (latestData.boxTemperature || 0) + ' 摄氏度'
              },
              {
                key: 'A路温度',
                value: (latestData.aTemperature || 0) + ' 摄氏度'
              },
              {
                key: 'B路温度',
                value: (latestData.bTemperature || 0) + ' 摄氏度'
              },
              {
                key: 'C路温度',
                value: (latestData.cTemperature || 0) + ' 摄氏度'
              },
              // {
              //   key: "A路电压",
              //   value: (aVoltage || 0) + " 伏特",
              // },
              // {
              //   key: "B路电压",
              //   value: (bVoltage || 0) + " 伏特",
              // },
              // {
              //   key: "C路电压",
              //   value: (cVoltage || 0) + " 伏特",
              // },
              // {
              //   key: "A路电流",
              //   value: (aCurrent || 0) + " 安培",
              // },
              // {
              //   key: "B路电流",
              //   value: (bCurrent || 0) + " 安培",
              // },
              // {
              //   key: "C路电流",
              //   value: (cCurrent || 0) + " 安培",
              // },
              {
                key: '漏电流',
                value: (latestData.aLeakCurrent || 0) + ' 安培'
              }
            ]
            this.removeDeviceAlarmLog(latestData.sn)
          }
        }
      })
    },
    /**
     * 环境监测
     */
    removeEnvensorDeviceInfo() {
      getEnvensorDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              // {
              //   key: "负责人",
              //   value: this.getContact(superintendentName,superintendentPhone),
              // },
              {
                key: '采集时间',
                value: parseTime(latestData.time, '{y}-{m}-{d} {h}:{i}:{s}')
              },
              {
                key: '报警次数',
                value: (latestData.alarmCount || 0) + ' 次'
              },
              {
                key: '黑匣/传感器名称',
                value: latestData.name
              },
              {
                key: '黑匣/传感器序号',
                value: latestData.sn
              },
              {
                key: '大气压',
                value: (latestData.atmospherePressure || 0) + ' 百帕'
              },
              {
                key: '噪音',
                value: (latestData.noise || 0) + ' 分贝'
              },
              {
                key: '湿度',
                value: latestData.humidity || 0
              },
              {
                key: 'PM10',
                value: (latestData.pM10 || 0) + ' ug/m³'
              },
              {
                key: 'PM2.5',
                value: (latestData.pM25 || 0) + ' ug/m³'
              },
              {
                key: '总悬浮颗粒物',
                value: (latestData.tsp || 0) + ' ug/m³'
              },
              {
                key: '风速',
                value: (latestData.windSpeed || 0) + ' m/s'
              },
              {
                key: '风向',
                value: this.windMap[latestData.windDirection] || '--'
              }
            ]
            this.removeDeviceAlarmLog(latestData.sn)
          }
        }
      })
    },
    /**
     * 视频
     */
    removeVideoDeviceInfo() {
      getVideoDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              {
                key: '负责人',
                value: this.getContact(
                  data.superintendentName,
                  data.superintendentPhone
                )
              }
            ]
          }
        }
      })
    },
    /**
     * 人行闸
     */
    removeManLockDeviceInfo() {
      getManLockDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            this.tableList = hasVal(latestData.attendances)
              ? latestData.attendances
              : []
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              {
                key: '负责人',
                value: this.getContact(
                  data.superintendentName,
                  data.superintendentPhone
                )
              }
            ]
          }
        }
      })
    },
    /**
     * 车道闸
     */
    removeDrivewayGatDeviceInfo() {
      getDrivewayGatDeviceInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.videoDevices = hasVal(latestData.videoDevices)
              ? latestData.videoDevices
              : []
            this.tableList = hasVal(latestData.vehicles)
              ? latestData.vehicles
              : []
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              {
                key: '负责人',
                value: this.getContact(
                  data.superintendentName,
                  data.superintendentPhone
                )
              },
              {
                key: '采集时间',
                value: parseTime(latestData.time, '{y}-{m}-{d} {h}:{i}:{s}')
              },
              //   {
              //     key: "报警次数",
              //     value: (latestData.alarmCount || 0) + " 次",
              //   },
              {
                key: '黑匣/传感器名称',
                value: latestData.name
              },
              {
                key: '黑匣/传感器序号',
                value: latestData.sn
              }
            ]
          }
        }
      })
    },
    /**
     * 智能AI
     */
    removeAIInfo() {
      getAIInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              {
                key: '采集时间',
                value: parseTime(latestData.time, '{y}-{m}-{d} {h}:{i}:{s}')
              },
              {
                key: '黑匣/传感器名称',
                value: latestData.name
              },
              {
                key: '人员数量',
                value: latestData.persons
              },
              {
                key: '没戴安全帽人数',
                value: latestData.noHats
              },
              {
                key: '没穿戴反光背心人数',
                value: latestData.noVests
              },
              {
                key: '识别图片地址',
                type: 'image',
                value: latestData.imagePath
              }
            ]
          }
        }
      })
    },
    /**
     * 高支模
     */
    removeHighFormworkInfo() {
      getHighFormworkInfo(this.record.id).then((res) => {
        const { data } = res
        if (hasVal(data)) {
          this.name = data.name
          if (hasVal(data.latestDatas)) {
            const latestData = data.latestDatas[0]
            this.baseInfo = [
              {
                key: '状态',
                value: latestData.statusTxt
              },
              // {
              //   key: "负责人",
              //   value: this.getContact(superintendentName,superintendentPhone),
              // },
              {
                key: '采集时间',
                value: parseTime(latestData.time, '{y}-{m}-{d} {h}:{i}:{s}')
              },
              {
                key: '报警次数',
                value: (latestData.alarmCount || 0) + ' 次'
              },
              {
                key: '黑匣/传感器名称',
                value: latestData.name
              },
              {
                key: '预警值',
                value: latestData.warnValue
              },
              {
                key: '报警值',
                value: latestData.alarmValue
              },
              {
                key: '实际值',
                value: latestData.value
              },
              {
                key: '电量值百分比值',
                value: latestData.voltage
              }
            ]
            this.removeDeviceAlarmLog(latestData.sn)
          }
        }
      })
    },
    getContact(name, phone) {
      let result = ''
      if (name && name.length > 0) {
        result += name
        if (phone && phone.length > 0) result += '（' + phone + '）'
      }
      return hasVal(result) ? result : '-'
    },
    previewImg(img) {
      var _arr = []
      _arr.push(img)
      return _arr
    },
    setState(tabIndex) {
      this.tabIndex = tabIndex
    },
    hasVal(val) {
      return hasVal(val)
    },
    existsTableList() {
      if (!hasVal(this.record)) return false
      return this.record.type === '人行闸' || this.record.type === '车道闸'
    },
    switchRhtSn(sn) {
      if (this.rhtSn === sn) return
      this.rhtSn = sn
      const _video = hasVal(this.rhtVideoOptions)
        ? this.rhtVideoOptions.filter((item) => item.sn === sn)
        : []
      if (hasVal(_video)) {
        this.videoDevices = _video[0].data
      }
      const _baseInfos = this.rhtOptions.filter((item) => item.sn === sn)
      if (hasVal(_baseInfos)) {
        this.baseInfo = _baseInfos[0].data
      }
      this.removeDeviceAlarmLog(sn)
    },
    /**
     * 打开视频监控
     */
    openVideo(video) {
      this.monitorItem = video
      this.dialogVideoTitle = video.name
      this.dialogVideoVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.machine-modal-title {
  display: flex;
  color: #ffffffc7;
  align-items: center;
  font-size: 24px;
  > img {
    width: 30px;
    margin-right: 20px;
    margin-left: 10px;
  }
}

.common-wrap {
  .lines {
    display: flex;
    align-items: center;
    color: #ffffffc7;
    margin-bottom: 10px;

    > div {
      margin-right: 20px;
      font-size: 15px;
    }
  }
  .video-group {
    display: flex;
    padding: 0 10px;
    height: 128px;
    .video-group-item {
      height: 128px;
      width: 110px;
      margin: 5px;
      .video-container {
        height: 98px;
      }
      .video-group-item-name {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5px;
        text-align: center;
        height: 20px;
        color: #61b5e5;
        font-size: 14px;
      }
    }
  }
}
.commn-info-list {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(94deg, #04245d, #09479b);

  .in-key {
    color: #ffffffc7;
    font-size: 20px;
    width: 48%;
    margin: 2px;
    > span {
      margin: 0 2px;
    }
  }
}
.info-status {
  display: inline-block;
  height: 26px;
  line-height: 24px;
  width: 64px;
  text-align: center;
  background-color: red;
  border-radius: 2px;
}
.basic-table {
  margin-top: 5px;
  width: 100%;
  line-height: 2;
  background: #0b2247;
}
.machine-tabs {
  background: #0b2247;
}
.tabs-header {
  display: flex;
  height: 44px;
  align-items: center;
  color: #61b5e5;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  > div {
    padding: 0 10px;
    padding-bottom: 5px;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 20px;
  }

  .tab-bottom {
    width: 100%;
    height: 1px;
    margin-left: 0px;
    padding-bottom: 0;
    position: absolute;
    background-color: #fff;
    opacity: 0.1;
    bottom: 8px;
  }

  .checked {
    color: #ffffffc7;
    border-bottom: 3px solid #fb8a4c;
  }
}
.fake-video {
  width: 110px;
  height: 90px;
  background-color: black;
  position: relative;
  .start-btn {
    width: 72px;
    height: 72px;
    background: #000000;
    opacity: 0.7;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > div {
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-left: 18px solid #ffffffc7;
      border-bottom: 14px solid transparent;
      opacity: 0.8;
      margin-left: 4px;
    }
  }
}
.warning-table {
  height: 200px;
  .t-header {
    width: 100%;
    display: flex;
  }
}
.empty-video {
  color: #ffffffc7;
  font-size: 18px;
  margin: 20px auto;
}
::v-deep .el-dialog__header {
  background: linear-gradient(94deg, #04245d, #09479b);
  border: 1px solid #0f5993;
  opacity: 0.96;
  .ant-modal-close-x {
    color: #ffffffc7;
  }
}
::v-deep .el-dialog__body {
  background: linear-gradient(94deg, #04245d, #09479b);
  padding: 10px 0;
}
::v-deep .el-dialog__footer {
  background: rgba(13, 68, 119, 0.92) !important;
}
::v-deep .el-button {
  border: rgba(4, 32, 89, 0) !important;
  background: rgba(4, 32, 89, 0) !important;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #41a5d8 !important;
}
</style>
