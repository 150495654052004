<template>
  <div class="machine-table-list-view">
    <div class="machine-list-context">
      <dv-scroll-board :config="config" class="dv-scroll-board" />
    </div>
  </div>
</template>
<script>
import { hasVal, parseTime } from '@/utils/index'
export default {
  name: 'MachineTableList',
  props: {
    isRxz: {
      type: Boolean,
      default: true
    },
    valOptions: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      config: null,
      rxzHeader: [
        '照片',
        '姓名',
        '班组',
        '入场时间',
        '入场图',
        '出场时间',
        '出场图',
        '停留（小时）'
      ],
      rxzColumnWidth: [80, 90, 140, 175, 95, 175, 95, 110],
      aligns: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center'
      ],
      cdzHeader: [
        '车牌号',
        '车牌颜色',
        '进场时间',
        '入场图',
        '出场时间',
        '出场图',
        '停留（小时）'
      ],
      cdzColumnWidth: [120, 100, 190, 110, 190, 110, 110],
      defaultAvatar: require('../../../assets/image/tab_icon_mine_default.png')
    }
  },
  watch: {
    valOptions(val) {
      if (this.isRxz) {
        this.removeManLockDeviceList()
      } else {
        this.removeDrivewayGatDeviceList()
      }
    }
  },
  methods: {
    removeManLockDeviceList() {
      if (hasVal(this.valOptions)) {
        const _data = []
        let _itemData = []
        this.valOptions.forEach((item) => {
          _itemData = []
          _itemData.push(
            "<img src='" +
              (hasVal(item.mugshot) ? item.mugshot : this.defaultAvatar) +
              "' />"
          )
          _itemData.push(item.name)
          _itemData.push(item.teamName)
          _itemData.push(
            parseTime(item.checkInTime, '{y}-{m}-{d} {h}:{i}:{s}')
          )
          _itemData.push(
            "<img style='height:80%;width:85%;margin-top:5%;' src='" +
              item.checkInPath +
              "' />"
          )
          _itemData.push(
            parseTime(item.checkOutTime, '{y}-{m}-{d} {h}:{i}:{s}')
          )
          if (hasVal(item.lastCheckInPath)) {
            _itemData.push(
              "<img style='height:80%;width:85%;margin-top:5%;' src='" +
                item.lastCheckInPath +
                "' />"
            )
          } else {
            _itemData.push('')
          }
          _itemData.push(item.workingHour)
          _data.push(_itemData)
        })
        this.config = {
          header: this.rxzHeader,
          columnWidth: this.rxzColumnWidth,
          align: this.aligns,
          data: _data,
          rowNum: 4,
          headerHeight: 50,
          headerBGC: 'rgba(14,64,128,.6)',
          evenRowBGC: '',
          oddRowBGC: 'rgba(14,64,128,.3)'
        }
      }
    },
    removeDrivewayGatDeviceList() {
      if (hasVal(this.valOptions)) {
        const _data = []
        let _itemData = []
        this.valOptions.forEach((item) => {
          _itemData = []
          _itemData.push(item.license)
          _itemData.push(item.color)
          _itemData.push(
            parseTime(item.checkInTime, '{y}-{m}-{d} {h}:{i}:{s}')
          )
          _itemData.push(
            "<img style='height:80%;width:85%;margin-top:5%;' src='" +
              item.checkInPath +
              "' />"
          )
          _itemData.push(
            parseTime(item.checkOutTime, '{y}-{m}-{d} {h}:{i}:{s}')
          )
          if (hasVal(item.lastCheckInPath)) {
            _itemData.push(
              "<img style='height:80%;width:85%;margin-top:5%;' src='" +
                item.lastCheckInPath +
                "' />"
            )
          } else {
            _itemData.push('')
          }
          _itemData.push(item.workingHour)
          _data.push(_itemData)
        })
        this.config = {
          header: this.cdzHeader,
          columnWidth: this.cdzColumnWidth,
          align: this.aligns,
          data: _data,
          rowNum: 4,
          headerHeight: 50,
          headerBGC: 'rgba(14,64,128,.6)',
          evenRowBGC: '',
          oddRowBGC: 'rgba(14,64,128,.3)'
        }
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.machine-table-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .machine-list-context {
    .dv-scroll-board {
      height: 220px;
    }
  }
}
</style>
